<template>
  <b-form-group
    label="Durum"
    label-for="status"
  >
    <validation-provider
      #default="{ errors }"
      name="Durum"
      rules="required"
    >
      <v-select
        id="status"
        v-model="itemData.status"
        :options="statuses"
        label="title"
        :reduce="item => item.id"
        placeholder="Durum"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ProductStatus',
  components: {
    ValidationProvider,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      statuses: [
        { id: '1', title: 'Aktif' },
        { id: '0', title: 'Pasif' },
      ],
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['productGroups/dataItem']
    },
  },
}
</script>
