<template>
  <b-form-group
    label="Ana Ürün Grubu"
    label-for="id_product_categories"
  >
    <v-select
      id="id_product_categories"
      v-model="itemData.id_product_categories"
      :options="productGroups"
      label="title"
      :reduce="item => item.id"
      placeholder="Ana Ürün Grubu"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ProductGroup',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    itemData() {
      return this.$store.getters['productGroups/dataItem']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
  },
}
</script>
